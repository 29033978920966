import { ReactNode, lazy } from 'react';
import { Navigate, RouteObject, generatePath } from 'react-router-dom';
import { ProductProvider, VendorProvider } from 'hooks';
import { EVendorUserType } from 'types/api';
import { Nullable } from 'types/common';
import { IBranchUserAuthInfo } from 'contexts/auth';
import { IntegrationsProvider } from 'contexts/integrations';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { EProductTabsKeys, PRODUCT_DETAILS_TAB_ROUTES } from 'components/Products/ProductDetails/routes.product-tabs';
import { EVendorBranchTabsKeys } from 'pages/BranchDetailsLayout/types';
import CampaignsList from 'pages/Campaigns';
import CreateCampaign from 'pages/Campaigns/CreateCampaign';
import { DEFAULT_DASHBOARD_URL_PARAMS } from 'pages/Dashboard/config';
import ErrorPage403 from 'pages/ErrorPage403';
import ErrorPage404 from 'pages/ErrorPage404';
import { InvoiceList } from 'pages/Invoices';
import OrderTabs from 'pages/Orders/OrderDetails/Tabs';
import { EOrderTabsKeys } from 'pages/Orders/OrderDetails/types';
import { OrderProvider } from 'pages/Orders/useOrderContext';
import DeliveryAreas from 'pages/VendorManagement/Tabs/DeliveryAreas';
import { EVendorTabsKeys } from 'pages/VendorManagement/types';
import { LIST_DEFAULT_PARAMS } from './api';
import {
	ApartmentOutlined,
	BlockOutlined,
	CloseCircleOutlined,
	DashboardOutlined,
	FileSearchOutlined,
	FileTextOutlined,
	InfoOutlined,
	MenuOutlined,
	OrderedListOutlined,
	RadiusSettingOutlined,
	RocketOutlined,
	ScheduleOutlined,
	ShopOutlined,
	ShoppingOutlined,
	UsergroupAddOutlined,
} from '@ant-design/icons';

const WIP_ROUTE = {
	path: '*',
	element: <>WIP</>,
	handle: {
		showOnNav: false,
	},
	children: [],
};

export interface INavMenuRoute {
	id?: string;
	index?: boolean;
	showOnNav: boolean;
	path?: string;
	icon?: ReactNode;
	translationKey?: string;
	children?: INavMenuRoute[];
	element?: ReactNode;
	defaultListParams?: Record<string, string>;
}

export const ABSOLUTE_ROUTES = {
	LOGIN: '/login',
	HOME: '/home',
	DASHBOARD: '/dashboard',
	DISPATCHER: '/dispatcher',
	CHANGE_PASSWORD: '/change-password',

	// ! orders
	ORDERS: '/orders',
	ORDER_DETAILS: '/orders/:id',

	// ! branches
	BRANCHES: '/branches',
	BRANCH_DETAILS: '/branch/:branchId',

	BRANCH_MENU_DETAILS: '/branch/menus/:menuId',
	BRANCH_MENU_PRODUCT_DETAILS_TAB: '/branch/menus/:menuId/product/:productId/:productTab',

	// ! Menu
	MENU: '/menu',

	// ! users
	BRANCH_USER_DETAILS: '/users/branch-users/details/:id',

	// ! Vendor Management
	VENDOR_DETAILS_TAB: '/vendor-management/:vendorId/details',
	VENDOR_PRODUCT_DETAILS_TAB: '/vendor-management/:vendorId/products/:productId/:productTab',
	VENDOR_MANAGEMENT_DETAILS_TAB: '/vendor-management/:vendorId/',
	VENDOR_MANAGEMENT_TAB: '/vendor-management/:vendorId/:vendorTab',
	VENDOR_MANAGEMENT_WORKSHIFT_TAB: '/vendor-management/:vendorId/working-shifts/:shiftId',
	VENDOR_MANAGEMENT_BRANCH_LIST_TAB: '/vendor-management/:vendorId/branches',
	VENDOR_MANAGEMENT_BRANCH_ID: '/vendor-management/:vendorId/branches/:branchId',
	VENDOR_MANAGEMENT_BRANCH_ID_TAB_DETAILS: '/vendor-management/:vendorId/branches/:branchId/details',
	VENDOR_MANAGEMENT_BRANCH_MENU_PRODUCT_DETAILS_TAB:
		'/vendor-management/:vendorId/branches/:branchId/menus/:menuId/product/:productId/:productTab',

	// ! Campaigns
	CAMPAIGN_DETAILS: '/campaigns/:vendorId',
	CAMPAIGN_CREATE: '/campaigns/:vendorId/new',

	// ! Earning Reports
	EARNING_REPORTS: '/earning-reports',

	// ! Cancelled Orders
	CANCELLED_ORDERS: '/cancelled-orders',

	// ! FORBIDDEN
	FORBIDDEN: '/forbidden',
	404: '/404',

	NO_ACCESS_ROUTE: '/',
};

export const getBranchDetailsUrl = (
	userAuthInfo: Nullable<IBranchUserAuthInfo>,
	vendorId: number,
	branchId: number
) => {
	return generatePath(
		userAuthInfo?.type === EVendorUserType.VENDOR_MANAGER
			? ABSOLUTE_ROUTES.VENDOR_MANAGEMENT_BRANCH_ID_TAB_DETAILS
			: ABSOLUTE_ROUTES.BRANCH_DETAILS,
		{
			vendorId,
			branchId,
		}
	);
};

const RedirectToRoleHomePage = lazy(() => import('pages/RedirectToRoleHomePage'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Dispatcher = lazy(() => import('pages/Dispatcher'));
const OrdersList = lazy(() => import('pages/Orders'));
const OrderDetailsPageLayout = lazy(() => import('pages/Orders/OrderDetails'));
const BranchDetailsLayout = lazy(() => import('pages/BranchDetailsLayout'));
const BranchMenuView = lazy(() => import('pages/Menu/BranchMenuView'));
const BranchMenuList = lazy(() => import('pages/Menu/MenuList'));
const DeliveryAreaPage = lazy(() => import('pages/DeliveryAreaPage'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const WorkShifts = lazy(() => import('pages/Branches/WorkShifts'));
const BranchWrapper = lazy(() => import('pages/Branches/BranchWrapper'));
const BranchDetails = lazy(() => import('pages/Branches/BranchDetails'));
const DetailsContent = lazy(() => import('pages/Branches/BranchDetails/DetailsContent/DetailsContent'));
const VendorMenuList = lazy(() => import('pages/VendorManagement/Tabs/Menus/VendorMenuList'));
const VendorMenuTabContent = lazy(() => import('pages/VendorManagement/Tabs/Menus/VendorMenuTabContent'));
const BranchesTableList = lazy(() => import('pages/VendorManagement/Tabs/Branches/BranchesList'));
const VendorBranchLayout = lazy(() => import('pages/VendorManagement/Tabs/Branches/BranchDetails/VendorBranchLayout'));

const BranchTimingsTabContent = lazy(
	() => import('pages/VendorManagement/Tabs/Branches/BranchDetails/Tabs/BranchTimingsTabContent')
);

// Products
const ProductList = lazy(() => import('pages/VendorManagement/Tabs/Products/ProductList'));
const ProductPageLayout = lazy(() => import('components/Products/ProductDetails'));
const ProductDetailsTab = lazy(() => import('components/Products/ProductDetails/Tabs'));

// users
const BranchUserDetails = lazy(() => import('pages/BranchUserDetails'));

// Vendor Management
const VendorManagementLayout = lazy(() => import('pages/VendorManagement/VendorManagementLayout'));
const DetailsTabContent = lazy(() => import('pages/VendorManagement/Tabs/Details'));
const StaffTabContent = lazy(() => import('pages/VendorManagement/Tabs/Staff/StaffTabContent'));

const CategoriesTabContent = lazy(() => import('pages/VendorManagement/Tabs/Categories/CategoriesTabContent'));
const WorkShiftsContent = lazy(() => import('pages/VendorManagement/Tabs/WorkShifts/WorkShiftContent'));
const WorkScheduleList = lazy(() => import('pages/VendorManagement/Tabs/WorkShifts/WorkShiftList'));

// Earning Reports
const EarningReport = lazy(() => import('pages/EarningReport'));

// Cancelled Orders
const CancelledOrders = lazy(() => import('pages/CancelledOrders'));

export interface IRouteObjectHandle {
	children?: TRouteObject[];
	handle?: {
		translationKey?: string;
		icon?: ReactNode;
		showOnNav?: boolean;
		isNavigateable?: boolean;
		country_white_list?: string[];
		roles?: EVendorUserType[];
	} & any;
}

export type TRouteObject = Omit<RouteObject, 'handle' | 'children'> & IRouteObjectHandle;

const getBranchMenuRoutes = (hasPageHeader: boolean): TRouteObject => ({
	path: EVendorBranchTabsKeys.MENUS,
	element: <BranchMenuList hasPageHeader={hasPageHeader} />,
	handle: {
		translationKey: EVendorBranchTabsKeys.MENUS,
		icon: <MenuOutlined />,
		showOnNav: true,
	},
	children: [
		{
			path: ':menuId',
			handle: {
				translationKey: 'menu',
				icon: <MenuOutlined />,
				showOnNav: false,
			},
			children: [
				{
					index: true,
					element: <BranchMenuView />,
				},
				{
					path: 'product/:productId',
					element: (
						<ProductProvider>
							<ProductPageLayout />
						</ProductProvider>
					),
					handle: {
						showOnNav: false,
					},
					children: [...PRODUCT_DETAILS_TAB_ROUTES],
				},
			],
		},
	],
});

export const DEFAULT_BRANCH_ADMIN_ROUTES_CONFIG: TRouteObject[] = [
	// ! Dashboard
	{
		id: 'dashboard',
		path: 'dashboard',
		element: <Dashboard />,
		handle: {
			translationKey: 'dashboard',
			icon: <DashboardOutlined />,
			showOnNav: true,
			defaultListParams: DEFAULT_DASHBOARD_URL_PARAMS,
			roles: [EVendorUserType.VENDOR_MANAGER, EVendorUserType.FINANCE_MANAGER],
		},
	},
	// ! Dispatcher
	{
		id: 'dispatcher',
		path: 'dispatcher',
		element: <Dispatcher />,
		handle: {
			translationKey: 'dispatcher',
			icon: <OrderedListOutlined />,
			roles: [EVendorUserType.VENDOR_MANAGER, EVendorUserType.BRANCH_MANAGER],
			showOnNav: true,
		},
	},
	// ! Orders
	{
		id: 'orders',
		path: 'orders',
		element: <ListParamsRouteWrapper key='orders' />,
		handle: {
			translationKey: 'orders',
			defaultListParams: LIST_DEFAULT_PARAMS,
			icon: <ShoppingOutlined />,
			showOnNav: true,
		},
		children: [
			{
				index: true,
				id: 'order-list',
				element: <OrdersList />,
				handle: {
					translationKey: 'orders',
					defaultListParams: LIST_DEFAULT_PARAMS,
					icon: <ShoppingOutlined />,
					showOnNav: false,
				},
			},
			{
				id: 'order-details',
				path: ':orderId',
				element: (
					<OrderProvider>
						<OrderDetailsPageLayout />
					</OrderProvider>
				),
				handle: {
					translationKey: 'orders',
					defaultListParams: LIST_DEFAULT_PARAMS,
					icon: <ShoppingOutlined />,
					showOnNav: false,
				},
				children: [
					{
						index: true,
						element: (
							<Navigate
								to={EOrderTabsKeys.DETAILS}
								replace
							/>
						),
					},
					{
						path: EOrderTabsKeys.DETAILS,
						element: <OrderTabs.OrderInfo />,
					},
					{
						path: EOrderTabsKeys.TIMELINE,
						element: <OrderTabs.TimelineTab />,
					},
				],
			},
		],
	},
	// ! Branch Details
	{
		id: 'branch',
		path: 'branch',
		element: (
			<IntegrationsProvider>
				<BranchWrapper />
			</IntegrationsProvider>
		),
		handle: {
			translationKey: 'branch',
			icon: <ShopOutlined />,
			showOnNav: true,
			roles: [EVendorUserType.BRANCH_MANAGER],
		},
		children: [
			{
				index: true,
				element: (
					<Navigate
						to={EVendorBranchTabsKeys.DETAILS}
						replace
					/>
				),
			},
			{
				path: EVendorBranchTabsKeys.DETAILS,
				element: <BranchDetails />,
				handle: {
					translationKey: 'branch_details',
					showOnNav: true,
				},
			},
			getBranchMenuRoutes(true),
			{
				path: EVendorBranchTabsKeys.WORK_SHIFT,
				element: <WorkShifts />,
				handle: {
					translationKey: 'work_shift',
					icon: <ScheduleOutlined />,
					showOnNav: true,
				},
			},

			{
				path: EVendorBranchTabsKeys.DELIVERY_AREA,
				element: <DeliveryAreaPage hasPageHeader />,
				handle: {
					translationKey: 'delivery_area',
					icon: <RadiusSettingOutlined />,
					showOnNav: true,
				},
			},
			{
				id: 'specific-branch',
				path: ':branchId',
				element: <BranchDetailsLayout />,
				handle: {
					showOnNav: false,
				},
				children: [
					{
						index: true,
						element: (
							<Navigate
								to={EVendorBranchTabsKeys.DETAILS}
								replace
							/>
						),
					},
					{
						path: EVendorBranchTabsKeys.DETAILS,
						element: <DetailsContent />,
						handle: {
							showOnNav: false,
						},
						children: [],
					},
					getBranchMenuRoutes(false),
					{
						path: EVendorBranchTabsKeys.WORK_SHIFT,
						element: <WorkShifts hasPageHeader={false} />,
						handle: {
							showOnNav: false,
						},
						children: [],
					},

					{
						path: EVendorBranchTabsKeys.DELIVERY_AREA,
						element: <DeliveryAreaPage />,
						handle: {
							showOnNav: false,
						},
						children: [],
					},
					WIP_ROUTE,
				],
			},
		],
	},
	// ! User Password Management
	{
		path: 'change-password',
		element: <ChangePassword />,
		handle: {
			showOnNav: false,
		},
	},
	// ! Branch User Details
	{
		path: 'users/branch-users/details/:id',
		element: <BranchUserDetails />,
		handle: {
			showOnNav: false,
		},
	},
	// ! Vendor Management
	{
		id: 'vendor-management',
		path: 'vendor-management/:vendorId',
		element: (
			<ListParamsRouteWrapper key='vendor-management'>
				<IntegrationsProvider>
					<VendorProvider>
						<VendorManagementLayout />
					</VendorProvider>
				</IntegrationsProvider>
			</ListParamsRouteWrapper>
		),
		handle: {
			translationKey: 'vendor_management',
			icon: <ApartmentOutlined />,
			showOnNav: true,
			roles: [EVendorUserType.VENDOR_MANAGER],
		},
		children: [
			{
				index: true,
				element: (
					<Navigate
						to={EVendorTabsKeys.DETAILS}
						replace
					/>
				),
			},
			{
				path: EVendorTabsKeys.DETAILS,
				handle: {
					translationKey: EVendorTabsKeys.DETAILS,
					icon: <InfoOutlined />,
				},
				children: [
					{
						index: true,
						element: <DetailsTabContent />,
					},
				],
			},
			{
				path: EVendorTabsKeys.CATEGORIES,
				handle: {
					translationKey: EVendorTabsKeys.CATEGORIES,
					icon: <BlockOutlined />,
				},
				children: [
					{
						index: true,
						element: <CategoriesTabContent />,
						handle: {
							showOnNav: false,
						},
					},
				],
			},
			{
				path: EVendorTabsKeys.PRODUCTS,
				handle: {
					translationKey: EVendorTabsKeys.PRODUCTS,
					icon: <BlockOutlined />,
				},
				element: <ListParamsRouteWrapper key='product-management' />,
				children: [
					{
						index: true,
						element: <ProductList />,
						handle: {
							showOnNav: false,
						},
					},
					{
						path: 'create',
						element: (
							<ProductProvider>
								<ProductPageLayout />
							</ProductProvider>
						),
						handle: {
							showOnNav: false,
						},
						children: [
							{
								index: true,
								element: <ProductDetailsTab isCreating={true} />,
							},
						],
					},
					{
						path: ':productId',
						element: (
							<ProductProvider>
								<ProductPageLayout />
							</ProductProvider>
						),
						handle: {
							showOnNav: false,
						},
						children: [
							{
								index: true,
								element: (
									<Navigate
										replace
										to={EProductTabsKeys.DETAILS}
									/>
								),
							},
							...PRODUCT_DETAILS_TAB_ROUTES,
						],
					},
				],
			},
			{
				path: EVendorTabsKeys.MENUS,
				handle: {
					translationKey: EVendorTabsKeys.MENUS,
					icon: <MenuOutlined />,
				},
				element: <ListParamsRouteWrapper key='menus-management' />,
				children: [
					{
						index: true,
						element: <VendorMenuList />,
						handle: {
							showOnNav: false,
						},
					},
					{
						path: ':menuId',
						element: <VendorMenuTabContent />,
						handle: {
							showOnNav: false,
						},
					},
				],
			},
			{
				path: EVendorTabsKeys.WORKING_SHIFTS,
				handle: {
					translationKey: EVendorTabsKeys.WORKING_SHIFTS,
					icon: <ScheduleOutlined />,
				},
				element: <ListParamsRouteWrapper key='work-shift-management' />,
				children: [
					{
						index: true,
						element: <WorkScheduleList />,
						handle: {
							showOnNav: false,
						},
					},
					{
						path: ':workShiftId',
						element: <WorkShiftsContent />,
						handle: {
							showOnNav: false,
						},
					},
				],
			},
			{
				id: 'vendor-branches',
				path: EVendorTabsKeys.BRANCHES,
				handle: {
					translationKey: EVendorTabsKeys.BRANCHES,
					icon: <ShopOutlined />,
				},
				element: <ListParamsRouteWrapper key='branch-management' />,
				children: [
					{
						index: true,
						element: <BranchesTableList />,
						handle: {
							showOnNav: false,
						},
					},
					{
						id: 'vendor-branch-details',
						path: ':branchId',
						element: (
							<BranchWrapper>
								<VendorBranchLayout />
							</BranchWrapper>
						),
						children: [
							{
								index: true,
								element: (
									<Navigate
										to={EVendorBranchTabsKeys.DETAILS}
										replace
									/>
								),
							},
							{
								path: EVendorBranchTabsKeys.DETAILS,
								element: <DetailsContent />,
								handle: {
									showOnNav: false,
								},
								children: [],
							},
							getBranchMenuRoutes(false),
							{
								path: EVendorBranchTabsKeys.WORK_SHIFT,
								element: <BranchTimingsTabContent />,
								handle: {
									translationKey: 'work_shift',
									icon: <ScheduleOutlined />,
									showOnNav: true,
								},
							},
							{
								path: EVendorBranchTabsKeys.DELIVERY_AREA,
								element: <DeliveryAreas />,
								handle: {
									translationKey: 'delivery_area',
									icon: <RadiusSettingOutlined />,
									showOnNav: true,
								},
							},
						],
					},
				],
			},
			{
				path: EVendorTabsKeys.INVOICES,
				element: (
					<ListParamsRouteWrapper key='invoice-management'>
						<InvoiceList />
					</ListParamsRouteWrapper>
				),
			},
			{
				path: EVendorTabsKeys.STAFF,
				element: (
					<ListParamsRouteWrapper key='staff-management'>
						<StaffTabContent />
					</ListParamsRouteWrapper>
				),
				handle: {
					translationKey: EVendorTabsKeys.STAFF,
					icon: <UsergroupAddOutlined />,
				},
				children: [],
			},
		],
	},
	// ! Campaigns
	{
		id: 'campaign-management',
		path: 'campaigns/:vendorId',
		children: [
			{ index: true, element: <CampaignsList /> },
			{ path: 'new', element: <CreateCampaign /> },
		],
		handle: {
			translationKey: 'campaign_management',
			icon: <RocketOutlined rotate={45} />,
			showOnNav: true,
			roles: [EVendorUserType.VENDOR_MANAGER],
		},
	},

	// ! Invoices
	{
		path: 'invoices',
		element: <InvoiceList />,
		handle: {
			translationKey: 'invoices',
			defaultListParams: LIST_DEFAULT_PARAMS,
			icon: <FileTextOutlined />,
			showOnNav: true,
			roles: [EVendorUserType.VENDOR_MANAGER, EVendorUserType.FINANCE_MANAGER],
		},
	},
	// ! Earning Reports
	{
		id: 'earning-reports',
		path: 'earning-reports',
		element: <EarningReport />,
		handle: {
			translationKey: 'earning_reports',
			icon: <FileSearchOutlined />,
			showOnNav: true,
			roles: [EVendorUserType.VENDOR_MANAGER, EVendorUserType.FINANCE_MANAGER],
		},
	},
	{
		id: 'cancelled-orders',
		path: 'cancelled-orders',
		element: <CancelledOrders />,
		handle: {
			translationKey: 'cancelled_orders',
			icon: <CloseCircleOutlined />,
			showOnNav: true,
			roles: [EVendorUserType.VENDOR_MANAGER, EVendorUserType.FINANCE_MANAGER],
		},
	},
	// ! Home
	{
		path: 'home',
		element: <RedirectToRoleHomePage />,
		handle: {
			showOnNav: false,
		},
	},
	// ! Others
	{
		path: 'forbidden',
		element: <ErrorPage403 />,
		handle: {
			showOnNav: false,
		},
	},
	{
		path: '404',
		element: <ErrorPage404 />,
		handle: {
			showOnNav: false,
		},
	},
	{
		path: '*',
		element: <ErrorPage404 />,
		handle: {
			showOnNav: false,
		},
	},
];
