import { CSSProperties, ReactNode } from 'react';
import dayjs from 'dayjs';
import { Input, InputProps, SelectProps } from 'antd';
import { Rule } from 'antd/es/form';
import { NamePath } from 'antd/es/form/interface';
import { TextAreaProps } from 'antd/es/input';
import { Locale } from 'antd/es/locale';
import { DirectionType } from 'antd/lib/config-provider';
import { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';

// ! types
export type TEmptyFunction = () => void;

export type TInputElementVariants = typeof Input | typeof Input.Password | typeof Input.TextArea;

export type TRangeValue = Nullable<NoUndefinedRangeValueType<dayjs.Dayjs>>;

// * common typescript

/** Utility to make type nullable. Ex: (string | null) */
export type Nullable<T> = T | null;

/** Utility to transform a type with a mix of required and optional properties into a new type where all the properties are required but some of them may be undefined */
export type Complete<T> = {
	[P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>> ? T[P] : T[P] | undefined;
};

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type NonEmptyArray<T> = [T, ...T[]];

// ! enums
export enum ESupportedLanguages {
	ENGLISH = 'en',
	ARABIC = 'ar',
}

export enum EOnOffStatus {
	OFFLINE = 'offline',
	ONLINE = 'online',
}

export enum EStatus {
	INACTIVE = 'inactive',
	ACTIVE = 'active',
}

export enum EChoiceOption {
	YES = 'yes',
	NO = 'no',
}

export enum ESupportedCountriesIsoTwoCodes {
	KUWAIT = 'kw',
	SAUDI_ARABIA = 'sa',
	UAE = 'ae',
	PORTUGAL = 'pt',
}

export enum EImgFileSize {
	DEEP_LINK_IMG = 307200, // (300 * 1024) 300Kb
	DEFAULT = 409600, // (400 * 1024) 400Kb
	CATEGORY = 409600, // (400 * 1024) 400Kb
	PRODUCT = 409600, // (400 * 1024) 400Kb
	BANNER = 512000, // (500 * 1024) 500Kb
	VERTICAL = 512000, // (500 * 1024) 500Kb
	BRANCH = 1048576, // (1 * 1024 * 1024) 1Mb
	DOCUMENT = 5242880, // (5 * 1024 * 1024) 5Mb
}

export enum EFileType {
	JPEG = 'image/jpeg',
	PNG = 'image/png',
	PDF = 'application/pdf',
}

export enum EDeliveryMode {
	// PICK_UP = 'pick_up', // the customer picks up the order himself - Unnecessary for the time being.
	PLATFORM = 'platform', // the delivery is done by the platform through a driver assigned by the platform
	VENDOR = 'vendor', // the delivery is done directly by the vendor
}

export enum EAssignmentMode {
	// NIL = 'nil', // The order is never assigned. - Unnecessary for the time being.
	AUTOMATIC = 'automatic', // The order is automatically assigned to the nearest driver
	MANUAL = 'manual', // The order is exclusively assigned by an operations team member
}

export enum EUserTypes {
	ADMIN = 'admin',
	BRANCH = 'branch',
	CUSTOMER = 'customer',
	DRIVER = 'driver',
	GUEST = 'guest',
	INTEGRATION = 'integration',
	OPERATIONS = 'operations',
	SYSTEM = 'system',
	VENDOR = 'vendor',
}

export enum EEditMode {
	EDIT,
	VIEW,
	VIEW_INTEGRATION,
}

export type TShortWeekDay = 'ALL' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN';

// ! interfaces
export interface IListResponse<T> {
	count: number;
	data: T[];
}

export interface ISupportedLanguage {
	name: string;
	flag: string;
	iso_two_code: ESupportedLanguages;
	defaultDirection: DirectionType;
	locale: Locale;
}

export interface ITabItem {
	key: string;
	label: ReactNode;
	icon?: ReactNode;
	children?: ReactNode;
	style?: CSSProperties;
	disabled?: boolean;
}

export interface ITabItemConfig {
	key: string;
	icon: ReactNode;
}

export interface ISelectOption {
	label: ReactNode;
	value: number | string;
	disabled?: boolean;
	filterableLabel?: string;
	description?: ReactNode; // extra info
}

interface IRadioOption {
	label: string;
	value: number | string | boolean;
}

export interface IFormItem {
	key: NamePath;
	label?: string | ReactNode;
	readOnly?: boolean;
	initialValue?: unknown;
	placeholder?: string;
	validationsRules?: Rule[];
	inputSelectionOptions?: ISelectOption[];
	radioOptions?: IRadioOption[];
	inputElement?: TInputElementVariants;
	textAreaProps?: TextAreaProps;
	inputProps?: InputProps;
	selectProps?: SelectProps;
}

export interface IFilterItem {
	text: ReactNode;
	value: string | number | boolean;
}

export interface IPageHeader {
	hasPageHeader?: boolean;
}
